@import "tailwind.scss";

html {
  scroll-behavior: smooth;
}

* {
  font-family: "Saira", sans-serif;
  transition: 0.1s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
